import React from 'react';

export const cloneWithLanguageCode = languageCode => (element, i) => React.cloneElement(element, { languageCode });

export const getRequestParam = name => (new URL(window.document.location)).searchParams.get(name);

//iframe show hide
export const loginShowHide = (loginType, iframeSrc) => {
    let config_flag = getRequestParam('persona');
    let elem = document.querySelector("."+loginType+"-modal");

    if(!elem){
      //Create Iframe
      let iframe = document.createElement("iframe");
      iframe.className = "loginModal "+loginType+"-modal";
      iframe.src = iframeSrc;
      iframe.onload = function(){
        if(config_flag == 0 || config_flag == null){
          document.querySelector("."+loginType+"-modal").classList.add("show");
          document.querySelector(".loading-gif").classList.add("hide");
        }
      };
      document.body.appendChild(iframe);
    }else {
      //Destorys Iframe
      elem.parentNode.removeChild(elem);
    }
}
//Smooth scrolling
export const doScrolling = (element, duration) => {
    let scrollOffset = 80;
    // let scrollOffset = 0;
    let elementY = document.getElementById(element).offsetTop - scrollOffset;
    let startingY = window.pageYOffset;
    let diff = elementY - startingY;
    let start;

  //  console.log("elementY: " + elementY);
  //  console.log("startingY: " + startingY);
  //  console.log("diff: " + diff);
  //  console.log("start: " + start);


    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp;
        // Elapsed milliseconds since start of scrolling.
        let time = timestamp - start;
        // Get percent of completion in range [0, 1].
        let percent = Math.min(time / duration, 1);

        window.scrollTo(0, startingY + diff * percent);
        // console.log("scroll to position: " + startingY + diff * percent);

        // Proceed with animation as long as we wanted it to.
        if (time < duration) {
        window.requestAnimationFrame(step);
        }
    })
}

//Correcting carousel widths and heights
export const resizeSlider = () => {
//     console.log("PKEW")
//   let slick_track = document.querySelector('.slick-track');

//   if(slick_track){
//     let window_width = document.documentElement.clientWidth;
//     let i = 0;
//     let number_of_slides = document.querySelectorAll('.slick-slide').length;
//     let tallest_slide_height = document.querySelectorAll('.slick-slide')[0].clientHeight;

//     //Correct width of slider container and slides
//     let slider_container_width = document.querySelector('.slick-slider').clientWidth;
//     let slide_width = slider_container_width/number_of_slides;

//     // console.log('window width: ' + window_width);
//     // console.log("slider con width: " + slider_container_width);
//     // console.log("slide widths: " + slide_width);

//     //Sets width of slider container for when slides are on desktop view
//   if (window_width >= 1015){
//     document.querySelector('.slick-track').style.width = slider_container_width + "px";
//   }

//   //Set equal heights for slides
//   for (i = 0; i < number_of_slides; i++) {
//       // console.log("tallest slide height: " + tallest_slide_height);
//       // console.log("calc heights: " + document.querySelectorAll('.slick-slide')[i].clientHeight);


//       if (document.querySelectorAll('.slick-slide')[i].clientHeight > tallest_slide_height){
//           tallest_slide_height = document.querySelectorAll('.slick-slide')[i].clientHeight;
//       }
//   }
// //   console.log(tallest_slide_height);
//   for (i = 0; i < number_of_slides; i++) {
//       //Sets height of slides
//       document.querySelectorAll('.slick-slide')[i].style.height = tallest_slide_height + "px";

//       //Sets width for when slides are on desktop view
//       if (window_width >= 1015){
//         document.querySelectorAll('.slick-slide')[i].style.width = slide_width + "px";
//       }
//   }
//   }
}

//Quote Box parrallax -- MAKE MORE DYNAMIC, find number of 'main-item_quote' classes
// export const quoteBoxParrallax = () => {
//   let num_of_quoteBoxes = document.querySelectorAll('.main-item_quote').length;

//   window.addEventListener("scroll", function() {
//     let is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
//     let window_scroll_position = is_safari ? document.body.scrollTop : document.documentElement.scrollTop;

//     //let cta_position = document.getElementById('buy').offsetTop - 552;
//     let parent_cta_position = document.querySelector('.main-item_quote').parentElement.parentElement.offsetTop;
//     let cta_position = parent_cta_position - 0;
//     let quoteBoxPosition = (window_scroll_position / 15) - 45;

//     for(let j = 0; j < num_of_quoteBoxes; j++){
//       document.querySelectorAll('.main-item_quote')[j].style.bottom = quoteBoxPosition+'px';
//     }


//     // console.log('scroll top: ' + window_scroll_position);
//     // console.log('parent cta position: ' + parent_cta_position);
//     // console.log('cta position: ' + cta_position);
//     // console.log('quote box position: ' + quoteBoxPosition);
//   });
// }

//Initiate Animations
export const initAnimations = () => {
  // console.log('initAnimations fired');
  let window_width = document.documentElement.clientWidth;

  let hero_banner_h1 = document.querySelector('.home-content');
  let nav_scroll_item = document.querySelector('.scroll').getElementsByClassName('scroll-item');
  let home_nav_scroll_item = document.querySelector('.scroll_mobile').getElementsByClassName('scroll-item');
  let homeDefault_nav_link_item = document.querySelector('.home-content_text');
  let home_nav_link_item = document.querySelector('.home-subheader_mobile');

//   let hero_banner_h1 = document.querySelector('.home-content');
//   let nav_scroll_item = document.querySelector('.scroll-nav').getElementsByClassName('scroll-item');



  //hero_banner_h1.classList.add('active');

  let len = nav_scroll_item !== null ? nav_scroll_item.length : 0,
  i = 0, j=0, operationsCompleted = 0;

  if(window_width > 767){
    for(i; i < len; i++) {
      nav_scroll_item[i].classList.add('active');
      home_nav_scroll_item[i].classList.add('active');
      nav_scroll_item[i].classList.add('delay');
      home_nav_scroll_item[i].classList.add('delay');
      operation();
    }
  }
  // else {
  //   setActiveLoop ();
  // }
  function operation(){
    operationsCompleted++;
    // console.log('operations completed count: ' + operationsCompleted);
    if(operationsCompleted == len){
      setTimeout(function(){
        removeDelay();
      }, 1000)
    }
  }
//   function setActiveLoop () {
//     setTimeout(function () {
//       nav_scroll_item[i].classList.add('active');
//       home_nav_scroll_item[i].classList.add('active');
//       nav_scroll_item[i].classList.add('delay');
//       home_nav_scroll_item[i].classList.add('delay');
//        i++;
//        if (i < len) {
//         setActiveLoop();
//        }else {
//         setTimeout(function () {
//           if(homeDefault_nav_link_item){
//             homeDefault_nav_link_item.classList.add('active');
//             homeDefault_nav_link_item.classList.add('delay');
//           }else {
//             home_nav_link_item.classList.add('active');
//             home_nav_link_item.classList.add('delay');
//           }
//         }, 300)
//        }
//     }, 100)
//  }
 function removeDelay(){
  //  console.log('delay removed');
  for(j; j < len; j++) {
    nav_scroll_item[j].classList.remove('delay');
    home_nav_scroll_item[j].classList.remove('delay');
  }
 }
}

//Highlight Fixed Nav Item when scrolling to section
export const navHighlight = () => {
  //Select fixed nav scroll items
  let scroll_item_selector = document.querySelector('.fixed-nav_links').querySelectorAll('.scroll-item');

  let i;
  let fixed_nav_scroll_item_selector = [];
  for (i = 0; i < scroll_item_selector.length; i++) {
    fixed_nav_scroll_item_selector[i] = scroll_item_selector[i];
    // console.log('fixed_nav_scroll_item_selector: ' + fixed_nav_scroll_item_selector);
  }

  //Select children of .main
  let main_children_selector = document.querySelector('.main').children;
  // console.log("main_children_selector.length: " + main_children_selector.length);

  //Create array to hold selectors of .main children
  let top_offset = 200;
  let j;
  let main_child_selector = [];
  let main_child_top = [];
  for (j = 0; j < main_children_selector.length; j++) {
      main_child_selector[j] = main_children_selector[j];
      main_child_top[j] = main_child_selector[j].offsetTop - top_offset;
      // console.log('main_Child_top: ' + main_child_top);
  }

  let buy_section_top = document.getElementById('buy').offsetTop - top_offset;
  let buy_fixed_nav_selector = document.getElementById("buy-nav-item");

  let transfer_section_top = document.getElementById('transfer').offsetTop - top_offset;
  let transfer_fixed_nav_selector = document.getElementById("transfer-nav-item");

  // console.log("buy_section: " + buy_section_top);
  // console.log("transfer_Section_top: " + transfer_section_top);

  //Sets 'active' fixed nav underline
  window.addEventListener("scroll", function(){
    let is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    let is_edge = window.navigator.userAgent.indexOf("Edge") > -1;

    let scroll_offset = 200;
    // console.log("scrolling")


    // Detects Browser Info and Version

    let agent = navigator.userAgent;
    let window_scroll_position;

        if (is_edge) {
            window_scroll_position = document.body.scrollTop;
        }
        else if (is_safari) {
            window_scroll_position = document.body.scrollTop;

            let offsetVersion = (agent.indexOf("Safari"))!=-1;

            if ((offsetVersion=agent.indexOf("Version"))!=-1) {
                let fullVersion = agent.substring(offsetVersion+8);
                let shortVersion = fullVersion.substring(0, 2);

                if (shortVersion > 10) {
                    scroll_offset = 200;
                }
                else {
                    scroll_offset = 500;
                }
            }
        }
        else {
            window_scroll_position = document.documentElement.scrollTop;
        }



    // console.log('scroll top: ' + window_scroll_position);

    // EDGE
    // document.body.scrollTop

    // CHROME
    // document.documentElement.scrollTop;



    // removeActiveClass(scroll_item_selector);

    // main_child_top[0]; //section offset Top
    // fixed_nav_scroll_item_selector[0].classList.add('active');

    //add active class to fixed nav item when scrollTop >= section offsettop
    if(
      window_scroll_position < (main_child_top[1] - scroll_offset)
    ){
      removeActiveClass(scroll_item_selector);
      fixed_nav_scroll_item_selector[0].classList.add('active');
    }else if(
      //window_scroll_position >= main_child_top[1] &&
      window_scroll_position < (main_child_top[2] - scroll_offset)
    ){
      removeActiveClass(scroll_item_selector);
      fixed_nav_scroll_item_selector[1].classList.add('active');
    }else if(
      //window_scroll_position >= main_child_top[2] &&
      window_scroll_position < (main_child_top[3] - scroll_offset)
    ){
      removeActiveClass(scroll_item_selector);
      fixed_nav_scroll_item_selector[2].classList.add('active');
    }else if(
      //window_scroll_position >= main_child_top[3] &&
      window_scroll_position < (main_child_top[4] - scroll_offset)
    ){
      removeActiveClass(scroll_item_selector);
      fixed_nav_scroll_item_selector[3].classList.add('active');
    }else {
      //If user scrolls past last section, highlight last nav item
      removeActiveClass(scroll_item_selector);
      fixed_nav_scroll_item_selector[main_children_selector.length-1].classList.add('active');
    }
  });
}

//Removes active class on all elements with specific class
const removeActiveClass = (element_selector) => {
  let number_of_elements = element_selector.length;

  let i;
  for (i = 0; i < number_of_elements; i++) {
      element_selector[i].classList.remove('active');
  }
}
