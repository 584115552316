import React from 'react';
import loadingGif from '../assets/loading-gif.gif';

export function IframeSignInBuy(props) {
    const persona = props.persona;
    const toggleHiddenBuy = props.toggleHiddenBuy;
    if (persona != 0) {
        //Member already logged in
        return (
        <div className="iframe-con">
            <div className="iframeBG"></div>
            <div className="loading-gif">
                <img src={loadingGif} alt=""/>
            </div>
            {/* <iframe className="loginModal buy-modal hide" src="https://secure-stage.unitedmileageplus.com/CM_connect.jsp?CID=HlUJIwVXRrdQFIhiJkM409Kv0EjLoHXfPUjfri1QM-nDudxPhkP-KWTjtTA5izgZTgY6VpijPthjJx0j9.T1F1oSt3PI4CSlHU-QBPO4uIRJt4yYzbH.M9VfLw4l8lsH.MgURSaXt5qYf7Axw2dI3tIKTHZanwaY9zc.HvlSlJnQEhnd.a7J1fEPiOdTNGYsurrO5lBocKJNjt86wIlnsH3xtkJkx7E4OhM4Iy3Gajg-OZXBlkH8X1PT-8Ix1AbCC9rM2D580ufT.kd5CbbHaqOnk7zNY0jH5kUdmrxASV-2KGT7wyrngj5hTDzE.QJ0Bdd3Ttkdx1I8RmFRZIMEQ1fM3NQQZTWpDp5wNxlgHhO3j9BVhErfLhvIrzB5ABL4EzDeuMJxhWXPZ4iTxWjsokPGV.vHbyyK3Qwnl6H5SIBD8Fnm3mi9fTmiKSgpV474cjyBfWQJV4MDHKFuAwPTe6L2vjgBbgyZgk1RrEXfk2kJ9tQ0fbK8etbCznyqvgpUQ2JcXyaTGB6HcIHNQNvERYUGIKmIJPbkALijA6kCbeUsnD2i8ZbmS2JxJi.hsHHl&CKBR=1" frameBorder="0" ></iframe> */}
        </div>
        )
    }
    return (
        //Member not logged in
        <div className="iframe-con">
            <div className="iframeBG" onClick={toggleHiddenBuy}></div>
            <div className="close-x" onClick={toggleHiddenBuy}>x</div>
            <div className="loading-gif">
                <img src={loadingGif} alt=""/>
            </div>
            {/* <iframe className="loginModal buy-modal" src="https://secure-stage.unitedmileageplus.com/CM_connect.jsp?CID=HlUJIwVXRrdQFIhiJkM409Kv0EjLoHXfPUjfri1QM-nDudxPhkP-KWTjtTA5izgZTgY6VpijPthjJx0j9.T1F1oSt3PI4CSlHU-QBPO4uIRJt4yYzbH.M9VfLw4l8lsH.MgURSaXt5qYf7Axw2dI3tIKTHZanwaY9zc.HvlSlJnQEhnd.a7J1fEPiOdTNGYsurrO5lBocKJNjt86wIlnsH3xtkJkx7E4OhM4Iy3Gajg-OZXBlkH8X1PT-8Ix1AbCC9rM2D580ufT.kd5CbbHaqOnk7zNY0jH5kUdmrxASV-2KGT7wyrngj5hTDzE.QJ0Bdd3Ttkdx1I8RmFRZIMEQ1fM3NQQZTWpDp5wNxlgHhO3j9BVhErfLhvIrzB5ABL4EzDeuMJxhWXPZ4iTxWjsokPGV.vHbyyK3Qwnl6H5SIBD8Fnm3mi9fTmiKSgpV474cjyBfWQJV4MDHKFuAwPTe6L2vjgBbgyZgk1RrEXfk2kJ9tQ0fbK8etbCznyqvgpUQ2JcXyaTGB6HcIHNQNvERYUGIKmIJPbkALijA6kCbeUsnD2i8ZbmS2JxJi.hsHHl&CKBR=1" frameBorder="0" onLoad={alert('HI PETER')}></iframe> */}
        </div>
    )
  }
