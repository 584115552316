// export const IframeUnitedLoginDetect = () => (
//     //Login Transfer CID that grabs mv cookie data from United.com -- put 'Transfer CID' into iframe src once received
//     // <iframe src="" width="1" height="1" frameBorder="0"></iframe>

//     //Temp Test - the above transfer CID resolves to a iframe src similar to the one below - Show iframe if config = 0 or null, if not, remove iframe from DOM
//     <iframe src="https://staging.points.com/company/unitedairlines/united_persona_page/member_redirect.html?mv=dbf7db7f7bf292a483b31d4042609d0a6c1121cfb3da54412a905c1b528ba2bc9f65abd0c98bb928ed68b25e2ff78329762bc88e2485f43fac77c868aaa2c9925603725a96db183dc6f8f9f9c0ced2e871a9020f660b3858b084d021b6fdaa0cb64dacd5a168cb83fcb285bb70ad442a9afbfef9e383491053af6e80e4406a4b" width="1" height="1" frameBorder="0"></iframe>

//     //Temp Test - PROD
//     // <iframe src="https://buy.points.com/marketing/unitedairlines/united_persona_page/member_redirect.html?mv=dbf7db7f7bf292a483b31d4042609d0a6c1121cfb3da54412a905c1b528ba2bc9f65abd0c98bb928ed68b25e2ff78329762bc88e2485f43fac77c868aaa2c9925603725a96db183dc6f8f9f9c0ced2e871a9020f660b3858b084d021b6fdaa0cb64dacd5a168cb83fcb285bb70ad442a9afbfef9e383491053af6e80e4406a4b" width="1" height="1" frameBorder="0"></iframe>
// );


import React from 'react';

export function IframeUnitedLoginDetect(props) {
    const persona = props.persona;
    const toggleHiddenGift = props.toggleHiddenGift;
    if (persona != 0) {
        //Member already logged in
        return (
            <iframe className='united-login-detect-iframe' src="" width="0" height="0" frameBorder="0"></iframe>
        )
    }
    return (
         //Temp Test - the above transfer CID resolves to a iframe src similar to the one below - Show iframe if config = 0 or null, if not, remove iframe from DOM
        // <iframe className='united-login-detect-iframe' src="https://staging.points.com/company/unitedairlines/united_persona_page/member_redirect.html?mv=dbf7db7f7bf292a483b31d4042609d0a6c1121cfb3da54412a905c1b528ba2bc9f65abd0c98bb928ed68b25e2ff78329762bc88e2485f43fac77c868aaa2c9925603725a96db183dc6f8f9f9c0ced2e871a9020f660b3858b084d021b6fdaa0cb64dacd5a168cb83fcb285bb70ad442a9afbfef9e383491053af6e80e4406a4b" width="0" height="0" frameBorder="0"></iframe>

        // <iframe className='united-login-detect-iframe' src="https://buy.points.com/marketing/unitedairlines/landing_page/member_redirect.html?mv=dbf7db7f7bf292a483b31d4042609d0a6c1121cfb3da54412a905c1b528ba2bc9f65abd0c98bb928ed68b25e2ff78329762bc88e2485f43fac77c868aaa2c9923df9f37a8cb6208da0bd080f0104c41e78a1f70ffec133d8f969777328f1c1964ed932603d8bc3d808d97380432c035715de08652c98688df719a276aaa6b483" width="0" height="0" frameBorder="0"></iframe>
        <iframe className='united-login-detect-iframe' src="https://secure.unitedmileageplus.com/CM_connect.jsp?CID=n7k423KbeXywccXxmKY0pgxMS6ReBEbYQic9GQxFS6l5JfnPmCqUSBR1b1EUbJRmFPLjMDiRgBeF.N2n5GABNX6D512Ve9bK6Ue5b5a31bXCMcx3ou2N-SxjhbTV2spYSvPYjGMZouSz7sKLsDlFpSKZRGhQML1XgJIFNfjNn2aiNBU04OpDEZ8oGhrDhr6XR1dUi8hWWscYd2hbSluV-IinYwQkIfpywiaTj9rm4tHLOPz3ECFRcM-8opWjZPuoqVQAmgV6SBlhhCy70FW9Ve3uif9Miuj2b5IJvIGAkYOCmfpFEFwglD8iQoDxCvXHdFrriKoqRe.7lgQ.Hlu3Pgg9BUvSo05A.3DV2kA75Th35Q2s4ODbEIHOgkv4yS.qe-OmlNm2ZRIxaGJ0EKozrIYNsuFjEcwSCYTAwRDTtd-2LLpsR1zmuS.HJqdpvei84WrNA1ZtbSQmv-m5z3YzbQ__&CKBR=1" width="0" height="0" frameBorder="0"></iframe>


        //Temp Test - PROD
        // <iframe src="https://buy.points.com/marketing/unitedairlines/united_persona_page/member_redirect.html?mv=dbf7db7f7bf292a483b31d4042609d0a6c1121cfb3da54412a905c1b528ba2bc9f65abd0c98bb928ed68b25e2ff78329762bc88e2485f43fac77c868aaa2c9925603725a96db183dc6f8f9f9c0ced2e871a9020f660b3858b084d021b6fdaa0cb64dacd5a168cb83fcb285bb70ad442a9afbfef9e383491053af6e80e4406a4b" width="1" height="1" frameBorder="0"></iframe>
    )
  }

