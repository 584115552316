import React from 'react';
import loadingGif from '../assets/loading-gif.gif';

export function IframeSignInMultiply(props) {
    const persona = props.persona;
    const toggleHiddenMultiply = props.toggleHiddenMultiply;
    if (persona != 0) {
        //Member already logged in
        return (
        <div className="iframe-con">
            <div className="iframeBG"></div>
            <div className="loading-gif">
                <img src={loadingGif} alt=""/>
            </div>
            {/* <iframe className="loginModal Multiply-modal hide" src="https://secure-stage.unitedmileageplus.com/CM_connect.jsp?CID=cKrh-NlyJaRSlxlyw9RqpVUWBFXvWXrJjyrJ8sj8YrCsAsqZSbb1SeNJ-D0KwfdbJrdSmBD5tGAfgzrRvO83hbhEz6tkKFA9MH0RxMmv9UhpYrFVW-hI92.sb6LtwXFbXIM2rHSgyN98u6U09dMN1a03tiz.t-E6zkRk.GD16cYbpdwRZyHqEhYoqMkasstAf11hAZ6-Yo4SKFxd3FHecBxlzLtuq1bvgudsh6aDbI86azFzAjCv6PhR.o0zAh87-KYlq8Tk83lDRH98amvNHU8c6SF3GOa3ij-JoHIgFXuEPRiJvFuY7vo.nZfl82DiE5ml5LX9GqXpRyVyNKoSduGjpRTgmp8Mdk0fuXKPUdQgLyW2K6SO9IuX0RXBx58ukigAntamBeK5yXhn2zMC7zJMYEjTWuZXp9CfOZVfiTWakuneSWYC9iON9c8YO.2S9LSpqAsLBeufdtUSboJTiccvtH0tDbIOYTCSb5fpoprbH..vD-RxWkX5RL1H2p6JFIw81NjZX3auTHC8LAwNlJtUU3WmzJbch3dTL9eS1mXM-2u7n5s8nXbkziFPXRjN" frameBorder="0" ></iframe> */}
        </div>
        )
    }
    return (
        //Member not logged in
        <div className="iframe-con">
            <div className="iframeBG" onClick={toggleHiddenMultiply}></div>
            <div className="close-x" onClick={toggleHiddenMultiply}>x</div>
            <div className="loading-gif">
                <img src={loadingGif} alt=""/>
            </div>
            {/* <iframe className="loginModal Multiply-modal" src="https://secure-stage.unitedmileageplus.com/CM_connect.jsp?CID=cKrh-NlyJaRSlxlyw9RqpVUWBFXvWXrJjyrJ8sj8YrCsAsqZSbb1SeNJ-D0KwfdbJrdSmBD5tGAfgzrRvO83hbhEz6tkKFA9MH0RxMmv9UhpYrFVW-hI92.sb6LtwXFbXIM2rHSgyN98u6U09dMN1a03tiz.t-E6zkRk.GD16cYbpdwRZyHqEhYoqMkasstAf11hAZ6-Yo4SKFxd3FHecBxlzLtuq1bvgudsh6aDbI86azFzAjCv6PhR.o0zAh87-KYlq8Tk83lDRH98amvNHU8c6SF3GOa3ij-JoHIgFXuEPRiJvFuY7vo.nZfl82DiE5ml5LX9GqXpRyVyNKoSduGjpRTgmp8Mdk0fuXKPUdQgLyW2K6SO9IuX0RXBx58ukigAntamBeK5yXhn2zMC7zJMYEjTWuZXp9CfOZVfiTWakuneSWYC9iON9c8YO.2S9LSpqAsLBeufdtUSboJTiccvtH0tDbIOYTCSb5fpoprbH..vD-RxWkX5RL1H2p6JFIw81NjZX3auTHC8LAwNlJtUU3WmzJbch3dTL9eS1mXM-2u7n5s8nXbkziFPXRjN" frameBorder="0" onLoad={alert('HI PETER')}></iframe> */}
        </div>
    )
  }
