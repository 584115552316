import React from 'react';
import { Text } from './Text';
import { ScrollNav } from './Nav'
import { ScrollNavItem } from './NavItem'
// import { scrollNavItems } from '../App'
// import { cloneWithLanguageCode } from '../utils'
// import { getScrollNavComponent } from '../personas'
import { getScrollNavComponent } from '../personas'
import { cloneWithLanguageCode } from '../utils'


// import { languageSelector } from '../utils'

// import { LanguageSelector } from './LanguageSelector'




export const HomePersona = ({ children, languageCode, memberName, memberMiles, getScrollNavComponent }) => (

  <div className="home-content">
    <h1>{memberName}, <Text languageCode={languageCode} token="homePersona.suffix" /><br />{memberMiles.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} <Text languageCode={languageCode} token="homePersona.suffix2" /></h1>
    <h2 className="home-subheader_desktop"><Text languageCode={languageCode} token="homePersona.subHeader" /></h2>
    {children}

    <a href="" className="home-subheader_mobile"><Text languageCode={languageCode} token="homePersona.subHeader.mobile" /></a>
  </div>
);
