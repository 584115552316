import React from 'react';
import { Text } from './Text';
import { Button } from './Text';
import transferMilesIcon from '../assets/icons/transfer_miles_teal.svg';
import requestMilesBlueIcon from '../assets/icons/request_miles_teal.svg';

export const TransferEverydayLoyals = ({languageCode, toggleHiddenRequestTransfer}) => (
    <div className="main-item transfer clearfix" id="transfer">
        <div className="main-item_image">
            <div className="main-item_quote">
                <p><Text languageCode={languageCode} token="transfer.quote" /></p>
                <p><Text languageCode={languageCode} token="transfer.quote.name" /></p>
            </div>
            <div className="main-item_icon">
                <img src={transferMilesIcon} alt=""/>
            </div>
        </div>
        <div className="main-item_content">
            <h3><Text languageCode={languageCode} token="transfer.subheader" /></h3>
            <h2><Text languageCode={languageCode} token="transfer.header" /></h2>
            <h4><Text languageCode={languageCode} token="transfer.text" /></h4>
            <div className="main-item_buttons">
                <Button languageCode={languageCode} token="transfer.button.yourself" icon={transferMilesIcon} url="transfer.link.url" className="transfer-link-pcta" />
                <Button languageCode={languageCode} token="transfer.button.another" onClick={ toggleHiddenRequestTransfer } icon={requestMilesBlueIcon} className="request-transfer-link-pcta" />
            </div>
            <p><Text languageCode={languageCode} token="transfer.disclaimer1" /></p>
            <p><Text languageCode={languageCode} token="transfer.disclaimer2" /></p>
        </div>
    </div>
);
