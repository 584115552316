import React from 'react';
import { Text } from './Text';
import enUS from '../tokens/en-US.json';
import esES from '../tokens/es-ES.json';
import jpJP from '../tokens/jp-JP.json';
import ptBR from '../tokens/pt-BR.json';
// import LanguageSelector from '../components/LanguageSelector';
// import createOnInput from '../components/LanguageSelector';
import hamburgerIcon from '../assets/icons/hamburger.svg';
// import unitedMileageLogo from '../assets/United Logo/united_mileage_plus_rgb_w-sb_r_a1.png';
import unitedMileageLogo from '../assets/United Logo/mileageplus_logo.png';
import downArrowIcon from '../assets/icons/downArrow.svg';

export const translations = {
  'en-US': enUS,
  'es-ES': esES,
  'jp-JP': jpJP,
  'pt-BR': ptBR,
};

export function FixedNavMember({languageCode, memberName, memberMiles, memberNumber, scrollTo, persona}){
    console.log('persona: ' + persona);
    if (persona != 0) {
        return (
            <div className="hamburger-member_container">
                <div className="member-greeting">
                    <Text languageCode={languageCode} token="navMember.greeting" />&nbsp;
                    <p>{memberName}</p>
                </div>
                <div className="member-balance">
                    <Text languageCode={languageCode} token="navMember.balance" />&nbsp;
                    <p>{memberMiles.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}&nbsp;<Text languageCode={languageCode} token="navMember.balance.miles" /></p>
                </div>
                <div className="member-number">
                    <Text languageCode={languageCode} token="navMember.number" />&nbsp;
                    <p>{memberNumber}</p>
                </div>
            </div>
        )
    }else {
        return (
            <div></div>
        )
    }
}

export const Nav = ({ languageCode, children, scrollTo, root_path, toggleHamburger, languageSelector, toggleLanSelect }) => (
    // <div className="fixed-nav" id="fixed-nav">
        <div className="fixed-nav_container">
            <a href="https://www.united.com/en/us/" className="fixed-nav_logo">
                <img src={unitedMileageLogo} alt=""/>
            </a>
            <div className={root_path ? "hamburger" : "hide"} onClick={toggleHamburger}>
                <img src={hamburgerIcon} alt=""/>
            </div>
            <div className="fixed-nav_links">
                {children.map(child => React.cloneElement(child, { scrollTo, toggleHamburger }))}
                {/* {console.log(children)}  */}
            </div>
            <div className="nav-language-container">
                <label htmlFor="language-selector" onClick={toggleLanSelect}><Text languageCode={languageCode} token="nav.language" /></label>
                {languageSelector}
            </div>
        </div>
    // </div>
);


export const ScrollNav = ({ children, languageSelector, scrollTo }) => (
    <div className="scroll-nav">
        {children.map(child => React.cloneElement(child, { scrollTo }))}
        {/* {console.log(children)} */}
    </div>
);



export const HomeNav = ({ languageSelector, languageCode, url, toggleHamburger, toggleLanSelect, node, displayLanguage, handleKeyPress }) => (


    <nav className="home-nav">
        <div className="home-nav-container">
            <a href={translations[languageCode]["united.home.link.url"]}>
                <img src={unitedMileageLogo} alt=""/>
            </a>
            <div className="hamburger" onClick={toggleHamburger}>
                <img src={hamburgerIcon} alt=""/>
            </div>

            <div className="nav-language-container" ref={node}>
                {/* <label  role="menu"   htmlFor="language-selector" > */}

                <div className="nav-language-menu" aria-haspopup="true" onKeyUp={handleKeyPress} role="language menu" onClick={toggleLanSelect} tabIndex="0" aria-expanded={displayLanguage}>
                    <Text languageCode={languageCode} token="nav.language" />
                    <img className={displayLanguage ? 'flip-horizontal-bottom' : 'flip-horizontal-top'} src={downArrowIcon} alt=""/>
                    {languageSelector}
                </div>
                {/* </label> */}
            </div>
        </div>
    </nav>
);
