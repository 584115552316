import React from 'react';
import loadingGif from '../assets/loading-gif.gif';

export function IframeSignInSubscribe(props) {
    const persona = props.persona;
    const toggleHiddenSubscribe = props.toggleHiddenSubscribe;
    if (persona != 0) {
        //Member already logged in
        return (
        <div className="iframe-con">
            <div className="iframeBG"></div>
            <div className="loading-gif">
                <img src={loadingGif} alt=""/>
            </div>
            {/* <iframe className="loginModal Subscribe-modal hide" src="https://secure-stage.unitedmileageplus.com/CM_connect.jsp?CID=uY2ZG2uTXcHs69W9L-4Qez9VZgU2Qje757N.RZDunFrYSErjbMqLQB922OrJmpBCaBh2vcqVWfnnf48GdT1xu-QPt8KjrxL3s8.sEsdhhflEK3K43rKpVR6OzVr.JiVEEvV5cz3XB1Cd6bcdGwE9Ozv.3Bw7ABYi3j24JYajY7nzDnFblkS6bJP4ZJv7GibMoKfUiH46oGiI92dR2GnCKwxyF6hzA8DnyIHcT9Y9SRsV9lLT49xWO3ndYOVTRfDd0p8IdC-7XdQz9MkY0YM-gJhNbtSKiqxetRJ6bsWmWYqiTDscg9GY.myW33ij875hb4BwTg2nMSsW2IceL9gxaZYCCETpzB65fN01OusQg75QYn.8vSLKt-3iZLtacaU6FB.8cGXqcefdrhYyw7-JqCGUL0KEepOoF8.r6gTPX5T.00cCldsX0JitD9rpbdguQqYkjUmhz7fpnDSGxb6RqZphwZk-pDQOatXd0zrGHrBlm6fQPGtNKvJ6tc0dTXBZpV-g857wmEKErCBxnP8uqvy6oEab5XzKmYU5wT9YFUA_" frameBorder="0" ></iframe> */}
        </div>
        )
    }
    return (
        //Member not logged in
        <div className="iframe-con">
            <div className="iframeBG" onClick={toggleHiddenSubscribe}></div>
            <div className="close-x" onClick={toggleHiddenSubscribe}>x</div>
            <div className="loading-gif">
                <img src={loadingGif} alt=""/>
            </div>
            {/* <iframe className="loginModal Subscribe-modal" src="https://secure-stage.unitedmileageplus.com/CM_connect.jsp?CID=uY2ZG2uTXcHs69W9L-4Qez9VZgU2Qje757N.RZDunFrYSErjbMqLQB922OrJmpBCaBh2vcqVWfnnf48GdT1xu-QPt8KjrxL3s8.sEsdhhflEK3K43rKpVR6OzVr.JiVEEvV5cz3XB1Cd6bcdGwE9Ozv.3Bw7ABYi3j24JYajY7nzDnFblkS6bJP4ZJv7GibMoKfUiH46oGiI92dR2GnCKwxyF6hzA8DnyIHcT9Y9SRsV9lLT49xWO3ndYOVTRfDd0p8IdC-7XdQz9MkY0YM-gJhNbtSKiqxetRJ6bsWmWYqiTDscg9GY.myW33ij875hb4BwTg2nMSsW2IceL9gxaZYCCETpzB65fN01OusQg75QYn.8vSLKt-3iZLtacaU6FB.8cGXqcefdrhYyw7-JqCGUL0KEepOoF8.r6gTPX5T.00cCldsX0JitD9rpbdguQqYkjUmhz7fpnDSGxb6RqZphwZk-pDQOatXd0zrGHrBlm6fQPGtNKvJ6tc0dTXBZpV-g857wmEKErCBxnP8uqvy6oEab5XzKmYU5wT9YFUA_" frameBorder="0" onLoad={alert('HI PETER')}></iframe> */}
        </div>
    )
  }
