import React from 'react';
import { Text } from './Text';

export const NavItem = ({ languageCode, token, url }) => (
    <a href={url}>
        <Text languageCode={languageCode} token={token} />
    </a>
);

export const FixedNavItem = ({ languageCode, token, url, scrollTo, id, toggleHamburger, className }) => (
    <a className={"scroll-item " + className} id={id} onClick={() => {scrollTo(id); toggleHamburger();}} href="#">
        <Text languageCode={languageCode} token={token} />
    </a>
);

export const ScrollNavItem = ({ languageCode, token, url, img, scrollTo, id, className }) => (
    <a className={"scroll-item " + className} id={id} onClick={() => scrollTo(id)} tabIndex="0">
        <img src={img} alt=""/>
        <Text languageCode={languageCode} token={token} />
    </a>
);
