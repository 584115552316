import React from 'react';
import Slider from 'react-slick';
import { Text } from './Text';
import { MileagePlusSlide } from './MileagePlusSlide';
import { RewardPlusSlide } from './RewardPlusSlide';
import { PointsHoundSlide } from './PointsHoundSlide';
import { EarnRedeemSlide } from './EarnRedeemSlide';
import { GiftcardsSlide } from './GiftcardsSlide';
import { MerchandiseAwardsSlide } from './MerchandiseAwardsSlide';
import { AwardAcceleratorSlide } from './AwardAcceleratorSlide';

export const EarnNoGroupon = ({languageCode, settings, url}) => (
    <section className="earn" id="earn">
        <h2><Text languageCode={languageCode} token="earn.header" /></h2>
        <Slider {...settings} className="earn-container">
            <MerchandiseAwardsSlide languageCode={languageCode} url="merchandiseawards.link.url" slide_heading_text="merchandiseawards.slide.header" slide_text="merchandiseawards.slide.text" />
            {/* <GiftcardsSlide languageCode={languageCode} url="giftcards.link.url" slide_heading_text="giftcard.slide.header" slide_text="giftcard.slide.text" /> */}
            <MileagePlusSlide languageCode={languageCode} url="mileageplus.link.url" slide_heading_text="mileageplus.slide.header" slide_text="mileageplus.slide.text" />
            <EarnRedeemSlide languageCode={languageCode} url="earnredeem.link.url" slide_heading_text="earnredeem.slide.header" slide_text="earnredeem.slide.text" />
            {/* <AwardAcceleratorSlide languageCode={languageCode} url="awardaccelerator.link.url" slide_heading_text="awardaccelerator.slide.header" slide_text="awardaccelerator.slide.text" /> */}
            {/* <PointsHoundSlide languageCode={languageCode} url="pointshound.link.url" slide_heading_text="pointshound.slide.header" slide_text="pointshound.slide.text" /> */}

        </Slider>
    </section>
);
