import React, { Component } from 'react';
import './styles/App.css';
import { Home } from './components/Home';
import { Text } from './components/Text';
import { LanguageSelector } from './components/LanguageSelector'
import { Main } from './components/Main';
import { Nav, ScrollNav, HomeNav, FixedNav, FixedNavMember } from './components/Nav';
// import { ScrollNav } from './components/Nav';
import { getMainComponent, getNavComponent, getScrollNavComponent, getFixedNavComponent } from './personas';
import { cloneWithLanguageCode, getRequestParam, loginShowHide, doScrolling, resizeSlider, quoteBoxParrallax, initAnimations, navHighlight } from './utils';
import { HomeDefault } from './components/HomeDefault';
import { HomePersona } from './components/HomePersona';
import { Footer } from './components/Footer';
import { IframeUnitedLoginDetect } from './components/IframeUnitedLoginDetect';
import { IframeSignInBuy } from './components/IframeSignInBuy';
import { IframeSignInGift } from './components/IframeSignInGift';
import { IframeSignInRequestTransfer } from './components/IframeSignInRequestTransfer';
import Slider from 'react-slick';
import slider from 'react-slick/lib/slider';
import Media from "react-media";
import createOnInput from './components/LanguageSelector';
// import video from './assets/GettyImages-697155202.mp4';
import { IframeSignInMultiply } from './components/IframeSignInMultiply';
import { IframeSignInSubscribe } from './components/IframeSignInSubscribe';

let languageCode_param = getRequestParam('languageCode');
let memberName_param = getRequestParam('memberName');
let memberMiles_param = getRequestParam('memberMiles');
let memberNumber_param = getRequestParam('memberNumber');
let persona_param = getRequestParam('persona');
let utmSource_param = getRequestParam('utm_source');
let utmMedium_param = getRequestParam('utm_medium');
let utmCampaign_param = getRequestParam('utm_campaign');
let utmTerm_param = getRequestParam('utm_term');
let utmContent_param = getRequestParam('utm_content');
let gclid_param = getRequestParam('gclid');
let gclsrc_param = getRequestParam('gclsrc');
let cjevent_param = getRequestParam('cjevent');
let fbclid_param = getRequestParam('fbclid');
let clickid_param = getRequestParam('clickid');
let referralCode_param = getRequestParam('referralCode');


class App extends Component {
  constructor() {
    super();
    // eg. http://localhost:3000/?languageCode=fr-FR&memberName=Alf&memberMiles=10&persona=2
    this.state = {
      languageCode: languageCode_param || 'en-US',
      memberName: memberName_param || 'Kaizer',
      memberMiles: memberMiles_param || 250000,
      memberNumber: memberNumber_param || 'A3274248274',
      persona: persona_param || 0,
      isHiddenBuy: true,
      isHiddenGift: true,
      isHiddenMultiply: true,
      isHiddenSubscribe: true,
      isHiddenRequestTransfer: true,
      hamburgerActive: false,
      displayLanguage: false,      trackingParameter: {
        utmSource: utmSource_param === null ? null : 'utm_source=' + utmSource_param,
        utmMedium: utmMedium_param === null ? null : 'utm_medium=' + utmMedium_param,
        utmCampaign: utmCampaign_param === null ? null : 'utm_campaign=' + utmCampaign_param,
        utmTerm: utmTerm_param === null ? null : 'utm_term=' + utmTerm_param,
        utmContent: utmContent_param === null ? null : 'utm_content=' + utmContent_param,
        gclid: gclid_param === null ? null : 'gclid=' + gclid_param,
        gclsrc: gclsrc_param === null ? null : 'gclsrc=' + gclsrc_param,
        cjevent: cjevent_param === null ? null : 'cjevent=' + cjevent_param,
        fbclid: fbclid_param === null ? null : 'fbclid=' + fbclid_param,
        clickid: clickid_param === null ? null : 'clickid=' + clickid_param,
        referralCode: referralCode_param === null ? null : 'referralCode=' + referralCode_param,
      },
    }

    this.toggleHiddenBuy = this.toggleHiddenBuy.bind(this);
    this.toggleHiddenGift = this.toggleHiddenGift.bind(this);
    this.toggleHiddenRequestTransfer = this.toggleHiddenRequestTransfer.bind(this);
    this.toggleHiddenMultiply = this.toggleHiddenMultiply.bind(this);
    this.toggleHiddenSubscribe = this.toggleHiddenSubscribe.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.toggleHamburger = this.toggleHamburger.bind(this);
    this.toggleLanSelect = this.toggleLanSelect.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.keyPressLanguage = this.keyPressLanguage.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.persistParameters = this.persistParameters.bind(this);

  }

  setTitle = () => {
    if (this.state.languageCode == 'en-US') {
      document.title = "Buy or Transfer MileagePlus Miles";
    }
    if (this.state.languageCode == 'es-ES') {
      document.title = "Comprar o transferir millas de MileagePlus";
    }
    if (this.state.languageCode == 'jp-JP') {
      document.title = "マイレージプラスマイルの購入または移行";
    }
    if (this.state.languageCode == 'pt-BR') {
      document.title = "Compre ou Transfira Milhas do MileagePlus";
    }
  }

  toggleLanSelect = () => {
    this.setState({
      displayLanguage: !this.state.displayLanguage
    })
  }

  createOnInput = callback => (event) => {
    const { id } = event.target;
    callback(id);
  };

  toggleHamburger = () => {
    this.setState({
      hamburgerActive: !this.state.hamburgerActive
    })
    // const hamburgerSelect = document.getElementById('hamburger-nav');
    // hamburgerSelect.classList.toggle('hamburger-show');
  }

  setLanguage(languageCode, setTitle) {
    this.setState(
      { languageCode },
    )
  }

  toggleHiddenBuy(e) {
    e.preventDefault();
    this.setState({
      isHiddenBuy: !this.state.isHiddenBuy
    })
    loginShowHide("buy", process.env.REACT_APP_BUY_LINK);
  }

  toggleHiddenGift(e) {
    e.preventDefault();
    this.setState({
      isHiddenGift: !this.state.isHiddenGift
    })
    loginShowHide("gift", process.env.REACT_APP_GIFT_LINK);
  }
  toggleHiddenMultiply(e) {
    e.preventDefault();
    this.setState({
      isHiddenMultiply: !this.state.isHiddenMultiply
    })
    loginShowHide("multiply", process.env.REACT_APP_MULTIPLY_LINK);
  }
  toggleHiddenSubscribe(e) {
    e.preventDefault();
    this.setState({
      isHiddenSubscribe: !this.state.isHiddenSubscribe
    })
    loginShowHide("subscribe", process.env.REACT_APP_SUBSCRIBE_LINK);
  }
  toggleHiddenRequestTransfer(e) {
    e.preventDefault();
    this.setState({
      isHiddenRequestTransfer: !this.state.isHiddenRequestTransfer
    })
    loginShowHide("request_transfer", process.env.REACT_APP_TRANSFER_LINK);
  }

  scrollTo(id) {
    // document.activeElement.blur();
    console.log('id: ' + id);
    var scroll_target = id.slice(0, -9);
    console.log('scroll_target: ' + scroll_target);
    doScrolling(scroll_target, 500);
  }
  persistParameters() {
    let tileBuy = document.getElementById("buy-cta-button");
    let tileGift = document.getElementById("gift-cta-button");
    let tileMultiply = document.getElementById("multiply-cta-button");
    let tileSubscribe = document.getElementById("subscribe-cta-button");
    let tileTransfer = document.getElementById("transfer-cta-button");
    let tileRequest = document.getElementById("request-transfer-cta-button");

    let paramArray = [
      this.state.trackingParameter.utmSource,
      this.state.trackingParameter.utmMedium,
      this.state.trackingParameter.utmCampaign,
      this.state.trackingParameter.utmTerm,
      this.state.trackingParameter.utmContent,
      this.state.trackingParameter.gclid,
      this.state.trackingParameter.gclsrc,
      this.state.trackingParameter.cjevent,
      this.state.trackingParameter.fbclid,
      this.state.trackingParameter.clickid,
      this.state.trackingParameter.referralCode
    ];
  
      let paramString = '';

      for (let x = 0; x < paramArray.length; x++) {

        if (paramArray[x] === null) {
          continue;
        }
        else {
          paramString = paramString.concat("&", paramArray[x]);
        }
        
      }
      
      tileBuy.href = (tileBuy.href + paramString);
      tileGift.href = (tileGift.href + paramString);
      tileTransfer.href = (tileTransfer.href + paramString);
      tileRequest.href = (tileRequest.href + paramString);
      tileSubscribe.href = (tileSubscribe.href + paramString);
      tileMultiply.href = (tileMultiply.href + paramString);
    }



  componentWillMount() {
    document.addEventListener('click', this.handleClick, false);
  }

  componentDidUpdate() {
    this.setTitle();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    initAnimations();
    // quoteBoxParrallax();
    navHighlight();
    this.persistParameters();
    setTimeout(function () {
      resizeSlider();
    }, 500);
    window.addEventListener("resize", resizeSlider);


    setTimeout(function () {
      //Sets URL to 'clean' URL without personas
      window.history.replaceState({}, document.title, "/" + "united/united_landing_page/");
      // For staging UAT purposes
      // window.history.replaceState({}, document.title, "/" + "company/unitedairlines/united_persona_rebrand/");

    }, 3000);
  };


  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);

  };

  //   If user clicks outside of language select menu, close language select menu

  handleClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  }

  handleClickOutside(e) {
    this.setState({
      displayLanguage: false
    })
  }


  handleScroll(e) {
    this.scrollFunction();
  }

  keyPressLanguage = (e) => {
    console.log("hello")
    this.setState({
      displayLanguage: true
    });
  }

  handleKeyPress = (e) => {
    if (e.key == "Enter") {
      console.log("Entered")
      // this.keyPressLanguage();
      this.setState({
        displayLanguage: true
      });
    }
    if (e.key == "Escape") {
      console.log("Escaped")
      this.setState({
        displayLanguage: false
      })
    }
  }

  scrollFunction() {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      const fixedNavClass = document.getElementById("fixed-nav");
      fixedNavClass.classList.add("showNav");
    }

    else {

      const fixedNavClass = document.getElementById("fixed-nav");

      fixedNavClass.classList.remove("showNav");
    }
  };

  render() {

    const { languageCode, memberName, memberMiles, memberNumber, persona, displayLanguage } = this.state;

    const mainItems = getMainComponent(persona, { toggleHiddenBuy: this.toggleHiddenBuy }, { toggleHiddenGift: this.toggleHiddenGift }, { toggleHiddenRequestTransfer: this.toggleHiddenRequestTransfer });
    const navItems = getNavComponent(persona);
    const scrollNavItems = getScrollNavComponent(persona, { scrollTo: this.scrollTo });
    const fixedNavItems = getFixedNavComponent(persona, { scrollTo: this.scrollTo });
    const languageSelector = <LanguageSelector createOnInput={this.createOnInput} callback={this.setLanguage} displayLanguage={displayLanguage} languageCode={languageCode} toggleLanSelect={this.toggleLanSelect} />;
    const memberProfile = this.state.persona;

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 1000000,
          settings: 'unslick'
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const nav =
      <ScrollNav className="scroll-nav" languageSelector={languageSelector} scrollTo={this.scrollTo}>
        {scrollNavItems.map(cloneWithLanguageCode(languageCode))}
      </ScrollNav>

    let homePage;

    if (memberProfile == 0) {
      homePage = (
        <HomeDefault languageCode={languageCode} memberName={memberName} memberMiles={memberMiles}>
          <section className="scroll_mobile">
            {nav}
          </section>
        </HomeDefault>
      )
    }
    else {
      homePage = (
        <HomePersona languageCode={languageCode} memberName={memberName} memberMiles={memberMiles} scrollNavItems={scrollNavItems}>
          <section className="scroll_mobile">
            {nav}
          </section>

        </HomePersona>
      )
    }

    // function bg_video(root_path, memberProfile) {
    //     if(memberProfile == 0) {
    //         <div className="bg-container">

    //             <div className="home-bg"></div>
    //             <video autoPlay="true" muted="true" loop="true" src={video} className="home-video">
    //             </video>
    //             <div className="video-overlay"></div>
    //         </div>
    //         console.log('hi')
    //     } else {
    //         <div className="bg-container">
    //             <div className="home-bg"></div>
    //             <div className="video-overlay"></div>
    //         </div>
    //         console.log('hello')
    //     }
    // }



    return (
      <div className="App">
        <IframeUnitedLoginDetect persona={persona} />
        {!this.state.isHiddenBuy && <IframeSignInBuy persona={persona} toggleHiddenBuy={this.toggleHiddenBuy} />}
        {!this.state.isHiddenGift && <IframeSignInGift persona={persona} toggleHiddenGift={this.toggleHiddenGift} />}
        {!this.state.isHiddenRequestTransfer && <IframeSignInRequestTransfer persona={persona} toggleHiddenRequestTransfer={this.toggleHiddenRequestTransfer} />}
        {!this.state.isHiddenMultiply && <IframeSignInMultiply persona={persona} toggleHiddenMultiply={this.toggleHiddenMultiply} />}
        {!this.state.isHiddenSubscribe && <IframeSignInSubscribe persona={persona} toggleHiddenSubscribe={this.toggleHiddenSubscribe} />}
        <section className="home" >

          <div className={this.state.hamburgerActive ? 'black black-show' : 'black'} onClick={this.toggleHamburger}></div>


          {/* <div className="home-bg"></div>
          <video autoPlay="true" muted="true" loop="true" src="{video}" className="home-video">
          </video>
          <HomeNav languageSelector={languageSelector} languageCode={languageCode} url="test.token"/>
          <Nav className="fixed-nav" scrollTo={this.scrollTo}>
            {fixedNavItems.map(cloneWithLanguageCode(languageCode))}
          </Nav>
          {homePage} */}

          {/* <div className="home-bg"></div> */}
          {/* <Media
            query="(min-width: 768px)"
            render={() => <video autoPlay="true" muted="true" loop="true" src={video} className="home-video" />}
          /> */}
          <Media
            query="(min-width: 768px)"
            render={() => <div className="home-banner" />}
          />

          <div className="video-overlay"></div>
          <div className="black"></div>

          <HomeNav displayLanguage={displayLanguage} languageSelector={languageSelector} languageCode={languageCode} url="test.token" toggleHamburger={this.toggleHamburger} toggleLanSelect={this.toggleLanSelect} handleKeyPress={this.handleKeyPress} createOnInput={this.createOnInput} node={node => this.node = node} />

          <div className="fixed-nav" id="fixed-nav">
            <Nav className="fixed-nav" languageCode={languageCode} languageSelector={languageSelector} scrollTo={this.scrollTo} scrollNavItems={scrollNavItems} toggleHamburger={this.toggleHamburger}>
              {fixedNavItems.map(cloneWithLanguageCode(languageCode))}
            </Nav>
          </div>


          <div className={this.state.hamburgerActive ? 'hamburger-nav hamburger-show' : 'hamburger-nav'} id="hamburger-nav">
            {/* <FixedNavMember memberName={memberName} memberMiles={memberMiles} memberNumber={memberNumber} languageCode={languageCode} persona={persona} /> */}
            <Nav className="fixed-nav" languageCode={languageCode} languageSelector={languageSelector} scrollTo={this.scrollTo} scrollNavItems={scrollNavItems} toggleHamburger={this.toggleHamburger}>
              {fixedNavItems.map(cloneWithLanguageCode(languageCode))}
            </Nav>
          </div>

          {homePage}
          <div className='location-label'><Text languageCode={languageCode} token="homeDefault.img.description" /></div>
        </section>
        <section className="scroll">
          {/* <ScrollNav className="scroll-nav" languageSelector={languageSelector} scrollTo={this.scrollTo}>
                {scrollNavItems.map(cloneWithLanguageCode(languageCode))}
            </ScrollNav> */}
          {nav}
        </section>
        <Main languageCode={languageCode} toggleHiddenBuy={this.toggleHiddenBuy} toggleHiddenGift={this.toggleHiddenGift} toggleHiddenRequestTransfer={this.toggleHiddenRequestTransfer} settings={settings}>
          {mainItems}
        </Main>
        <section className="footer" id="footer">
          <Footer languageCode={languageCode} toggleHiddenBuy={this.toggleHiddenBuy} toggleHiddenGift={this.toggleHiddenGift} toggleHiddenMultiply={this.toggleHiddenMultiply} toggleHiddenSubscribe={this.toggleHiddenSubscribe} toggleHiddenRequestTransfer={this.toggleHiddenRequestTransfer} />
        </section>
      </div>
    );
  }
}

export default App;
