import React from 'react';
import { Buy } from './components/Buy';
import { Earn } from './components/Earn';
import { EarnNoGroupon } from './components/EarnNoGroupon';
import { EarnNoGrouponNoPH } from './components/EarnNoGrouponNoPH';
import { EarnNoPH } from './components/EarnNoPH';
import { PointsHound } from './components/PointsHound';
import { NavItem } from './components/NavItem';
import { ScrollNavItem } from './components/NavItem';
import { FixedNavItem } from './components/NavItem';
//import { Reinstate } from './components/Reinstate';
import { Transfer } from './components/Transfer';
import { TransferEverydayLoyals } from './components/TransferEveryDayLoyals';
import { RequestTransfer } from './components/RequestTransfer';
import { RequestTransferEverydayLoyals } from './components/RequestTransferEverydayLoyals';
import { HomeDefault } from './components/HomeDefault';
import { HomePersona } from './components/HomePersona';
import buyMilesIcon from './assets/icons/buy_miles_teal.svg';
import moreWaysToEarnIcon from './assets/icons/more_ways_to_earn_teal.svg';
import earnAndRedeemIcon from './assets/icons/earn_and_redeem_teal.svg';
//import reinstateMilesIcon from './assets/icons/reinstate_miles_teal.svg';
import requestMilesBlueIcon from './assets/icons/request_miles_teal.svg';
import transferMilesIcon from './assets/icons/transfer_miles_teal.svg';

const TYPES = {
  MAIN: Symbol('MAIN'),
  NAV: Symbol('NAV'),
  HOME: Symbol('HOME'),
  SCROLL_NAV: Symbol('SCROLL_NAV'),
  FIXED_NAV: Symbol('FIXED_NAV'),
};

const NAMES = {
  BUY: Symbol('BUY'),
  EARN: Symbol('EARN'),
  EARN_NO_GROUPON: Symbol('EARN_NO_GROUPON'),
  EARN_NO_GROUPON_NO_PH: Symbol('EARN_NO_GROUPON_NO_PH'),
  EARN_NO_PH: Symbol('EARN_NO_PH'),
  POINTSHOUND: Symbol('POINTSHOUND'),
  //REINSTATE: Symbol('REINSTATE'),
  REQUEST_TRANSFER: Symbol('REQUEST_TRANSFER'),
  REQUEST_TRANSFER_EVERYDAY_LOYALS: Symbol('REQUEST_TRANSFER_EVERYDAY_LOYALS'),
  TRANSFER: Symbol('TRANSFER'),
  TRANSFER_EVERYDAY_LOYALS: Symbol('TRANSFER_EVERYDAY_LOYALS'),
//   HOME: Symbol('HOME'),
  HOME_DEFAULT: Symbol('HOME_DEFAULT'),
  HOME_PERSONA: Symbol('HOME_PERSONA'),
};

const components = {

//   [TYPES.HOME]: {
//     [NAMES.HOME_DEFAULT]: <HomeDefault />,
//     [NAMES.HOME_PERSONA]: <HomePersona />,
//   },
  [TYPES.MAIN]: {
    [NAMES.BUY]: <Buy key="buy" />,
    [NAMES.EARN]: <Earn key="earn" />,
    [NAMES.EARN_NO_GROUPON]: <EarnNoGroupon key="earn" />,
    [NAMES.EARN_NO_GROUPON_NO_PH]: <EarnNoGrouponNoPH key="earn" />,
    [NAMES.EARN_NO_PH]: <EarnNoPH key="earn" />,
    [NAMES.POINTSHOUND]: <PointsHound key="pointshound" />,
    //[NAMES.REINSTATE]: <Reinstate key="reinstate" />,
    [NAMES.REQUEST_TRANSFER]: <RequestTransfer key="transfer" />,
    [NAMES.REQUEST_TRANSFER_EVERYDAY_LOYALS]: <RequestTransferEverydayLoyals key="transfer" />,
    [NAMES.TRANSFER]: <Transfer key="transfer" />,
    [NAMES.TRANSFER_EVERYDAY_LOYALS]: <TransferEverydayLoyals key="transfer" />,
  },
  [TYPES.NAV]: {
    [NAMES.BUY]: <NavItem token="navItem.buy.label" url="http://example.com/buy" key="buy" />,
    [NAMES.EARN]: <NavItem token="navItem.earn.label" url="http://example.com/earn" key="earn" />,
    [NAMES.EARN_NO_GROUPON]: <NavItem token="navItem.earn.label" url="http://example.com/earn_no_groupon" key="earn" />,
    [NAMES.EARN_NO_GROUPON_NO_PH]: <NavItem token="navItem.earn.label" url="http://example.com/earn_no_groupon_no_ph" key="earn" />,
    [NAMES.EARN_NO_PH]: <NavItem token="navItem.earn.label" url="http://example.com/earn_no_ph" key="earn" />,
    [NAMES.POINTSHOUND]: <NavItem token="navItem.pointshound.label" url="http://example.com/pointshound" key="pointshound" />,
    //[NAMES.REINSTATE]: <NavItem token="navItem.reinstate.label" url="http://example.com/reinstate" key="reinstate" />,
    [NAMES.REQUEST_TRANSFER]: <NavItem token="navItem.transfer.label" url="http://example.com/request_transfer" key="transfer" />,
    [NAMES.REQUEST_TRANSFER_EVERYDAY_LOYALS]: <NavItem token="navItem.transfer.label" url="http://example.com/request_transfer" key="transfer" />,
    [NAMES.TRANSFER]: <NavItem token="navItem.transfer.label" url="http://example.com/transfer" key="transfer" />,
    [NAMES.TRANSFER_EVERYDAY_LOYALS]: <NavItem token="navItem.transfer.label" url="http://example.com/transfer" key="transfer" />,
  },
  [TYPES.SCROLL_NAV]: {
    [NAMES.BUY]: <ScrollNavItem token="navItem.buy.label" url="#buy" key="buy" img={buyMilesIcon} id="buy-nav-item" className="buy-nav-item-pcta" />,
    [NAMES.EARN]: <ScrollNavItem token="navItem.earn.label" url="#earn" key="earn" img={moreWaysToEarnIcon} id="earn-nav-item" className="earn-nav-item-pcta" />,
    [NAMES.EARN_NO_GROUPON]: <ScrollNavItem token="navItem.earn.label" url="#earn" key="earn" img={moreWaysToEarnIcon} id="earn-nav-item" className="earn-nav-item-pcta" />,
    [NAMES.EARN_NO_GROUPON_NO_PH]: <ScrollNavItem token="navItem.earn.label" url="#earn" key="earn" img={moreWaysToEarnIcon} id="earn-nav-item" className="earn-nav-item-pcta" />,
    [NAMES.EARN_NO_PH]: <ScrollNavItem token="navItem.earn.label" url="#earn" key="earn" img={moreWaysToEarnIcon} id="earn-nav-item" className="earn-nav-item-pcta" />,
    [NAMES.POINTSHOUND]: <ScrollNavItem token="navItem.pointshound.label" url="http://example.com/pointshound" key="pointshound" img={earnAndRedeemIcon} id="pointshound-nav-item" className="pointshound-nav-item-pcta" />,
    //[NAMES.REINSTATE]: <ScrollNavItem token="navItem.reinstate.label" url="#reinstate" key="reinstate" img={reinstateMilesIcon} id="reinstate-nav-item" className="reinstate-nav-item-pcta" />,
    [NAMES.REQUEST_TRANSFER]: <ScrollNavItem token="navItem.requestTransfer.label" url="http://example.com/request_transfer" key="transfer" img={requestMilesBlueIcon} id="transfer-nav-item" className="transfer-nav-item-pcta" />,
    [NAMES.REQUEST_TRANSFER_EVERYDAY_LOYALS]: <ScrollNavItem token="navItem.requestTransfer.label" url="http://example.com/request_transfer" key="transfer" img={requestMilesBlueIcon} id="transfer-nav-item" className="transfer-nav-item-pcta" />,
    [NAMES.TRANSFER]: <ScrollNavItem token="navItem.transfer.label" url="#transfer" key="transfer" img={transferMilesIcon} id="transfer-nav-item" className="transfer-nav-item-pcta" />,
    [NAMES.TRANSFER_EVERYDAY_LOYALS]: <ScrollNavItem token="navItem.transfer.label" url="#transfer" key="transfer" img={transferMilesIcon} id="transfer-nav-item" className="transfer-nav-item-pcta" />,
  },
  [TYPES.FIXED_NAV]: {
    [NAMES.BUY]: <FixedNavItem token="navItem.buy.label" url="#buy" key="buy" id="buy-nav-item" className="buy-fixed-nav-item-pcta" />,
    [NAMES.EARN]: <FixedNavItem token="navItem.earn.label" url="#earn" key="earn" id="earn-nav-item" className="earn-fixed-nav-item-pcta" />,
    [NAMES.EARN_NO_GROUPON]: <FixedNavItem token="navItem.earn.label" url="#earn" key="earn" id="earn-nav-item" className="earn-fixed-nav-item-pcta" />,
    [NAMES.EARN_NO_GROUPON_NO_PH]: <FixedNavItem token="navItem.earn.label" url="#earn" key="earn" id="earn-nav-item" className="earn-fixed-nav-item-pcta" />,
    [NAMES.EARN_NO_PH]: <FixedNavItem token="navItem.earn.label" url="#earn" key="earn" id="earn-nav-item" className="earn-fixed-nav-item-pcta" />,
    [NAMES.POINTSHOUND]: <FixedNavItem token="navItem.pointshound.label" url="http://example.com/pointshound" key="pointshound" id="pointshound-nav-item" className="pointshound-fixed-nav-item-pcta" />,
    //[NAMES.REINSTATE]: <FixedNavItem token="navItem.reinstate.label" url="#reinstate" key="reinstate" id="reinstate-nav-item" className="reinstate-fixed-nav-item-pcta" />,
    [NAMES.REQUEST_TRANSFER]: <FixedNavItem token="navItem.requestTransfer.label" url="http://example.com/request_transfer" key="transfer" id="transfer-nav-item" className="transfer-fixed-nav-item-pcta" />,
    [NAMES.REQUEST_TRANSFER_EVERYDAY_LOYALS]: <FixedNavItem token="navItem.requestTransfer.label" url="http://example.com/request_transfer" key="transfer" id="transfer-nav-item" className="transfer-fixed-nav-item-pcta" />,
    [NAMES.TRANSFER]: <FixedNavItem token="navItem.transfer.label" url="#transfer" key="transfer" id="transfer-nav-item" className="transfer-fixed-nav-item-pcta" />,
    [NAMES.TRANSFER_EVERYDAY_LOYALS]: <FixedNavItem token="navItem.transfer.label" url="#transfer" key="transfer" id="transfer-nav-item" className="transfer-fixed-nav-item-pcta" />,
  }
};

const NAMES_BY_PERSONA = {
  0: [NAMES.BUY, NAMES.TRANSFER, NAMES.EARN],
  //0: [NAMES.BUY, NAMES.TRANSFER, NAMES.REINSTATE, NAMES.EARN],
  1: [NAMES.TRANSFER, NAMES.BUY, NAMES.EARN],
  2: [NAMES.TRANSFER, NAMES.BUY, NAMES.EARN],
  //2: [NAMES.TRANSFER, NAMES.BUY, NAMES.REINSTATE, NAMES.EARN],
  3: [NAMES.TRANSFER, NAMES.BUY, NAMES.EARN_NO_GROUPON],
  //4: [NAMES.TRANSFER, NAMES.BUY, NAMES.REINSTATE, NAMES.EARN_NO_GROUPON],
  4: [NAMES.TRANSFER, NAMES.BUY, NAMES.EARN_NO_GROUPON],
  5: [NAMES.REQUEST_TRANSFER, NAMES.BUY, NAMES.EARN],
  6: [NAMES.REQUEST_TRANSFER, NAMES.BUY, NAMES.EARN],
  //6: [NAMES.REQUEST_TRANSFER, NAMES.BUY, NAMES.REINSTATE, NAMES.EARN],
  7: [NAMES.REQUEST_TRANSFER, NAMES.BUY, NAMES.EARN_NO_GROUPON],
  8: [NAMES.REQUEST_TRANSFER, NAMES.BUY, NAMES.EARN_NO_GROUPON],
//  8: [NAMES.REQUEST_TRANSFER, NAMES.BUY, NAMES.REINSTATE, NAMES.EARN_NO_GROUPON],
  9: [NAMES.POINTSHOUND, NAMES.TRANSFER_EVERYDAY_LOYALS, NAMES.BUY, NAMES.EARN_NO_PH],
  //10: [NAMES.POINTSHOUND, NAMES.TRANSFER_EVERYDAY_LOYALS, NAMES.BUY, NAMES.REINSTATE, NAMES.EARN_NO_PH],
  10: [NAMES.POINTSHOUND, NAMES.TRANSFER_EVERYDAY_LOYALS, NAMES.BUY, NAMES.EARN_NO_PH],
  11: [NAMES.POINTSHOUND, NAMES.TRANSFER_EVERYDAY_LOYALS, NAMES.BUY, NAMES.EARN_NO_GROUPON_NO_PH],
  //12: [NAMES.POINTSHOUND, NAMES.TRANSFER_EVERYDAY_LOYALS, NAMES.BUY, NAMES.REINSTATE, NAMES.EARN_NO_GROUPON_NO_PH],
    12: [NAMES.POINTSHOUND, NAMES.TRANSFER_EVERYDAY_LOYALS, NAMES.BUY, NAMES.EARN_NO_GROUPON_NO_PH],
  13: [NAMES.POINTSHOUND, NAMES.REQUEST_TRANSFER_EVERYDAY_LOYALS, NAMES.BUY, NAMES.EARN_NO_PH],
  14: [NAMES.POINTSHOUND, NAMES.REQUEST_TRANSFER_EVERYDAY_LOYALS, NAMES.BUY, NAMES.EARN_NO_PH],
//14: [NAMES.POINTSHOUND, NAMES.REQUEST_TRANSFER_EVERYDAY_LOYALS, NAMES.BUY, NAMES.REINSTATE, NAMES.EARN_NO_PH],
  15: [NAMES.POINTSHOUND, NAMES.REQUEST_TRANSFER_EVERYDAY_LOYALS, NAMES.BUY, NAMES.EARN_NO_GROUPON_NO_PH],
  16: [NAMES.POINTSHOUND, NAMES.REQUEST_TRANSFER_EVERYDAY_LOYALS, NAMES.BUY, NAMES.EARN_NO_GROUPON_NO_PH],
    //16: [NAMES.POINTSHOUND, NAMES.REQUEST_TRANSFER_EVERYDAY_LOYALS, NAMES.BUY, NAMES.REINSTATE,  NAMES.EARN_NO_GROUPON_NO_PH],
  17: [NAMES.BUY, NAMES.TRANSFER, NAMES.EARN],
  18: [NAMES.BUY, NAMES.TRANSFER, NAMES. EARN],
    //18: [NAMES.BUY, NAMES.TRANSFER, NAMES.REINSTATE, NAMES. EARN],
  19: [NAMES.BUY, NAMES.TRANSFER, NAMES.EARN_NO_GROUPON],
  20: [NAMES.BUY, NAMES.TRANSFER, NAMES.EARN_NO_GROUPON],
    //20: [NAMES.BUY, NAMES.TRANSFER, NAMES.REINSTATE, NAMES.EARN_NO_GROUPON],
  21: [NAMES.BUY, NAMES.REQUEST_TRANSFER, NAMES.EARN],
  //22: [NAMES.BUY, NAMES.REQUEST_TRANSFER, NAMES.REINSTATE, NAMES. EARN],
    22: [NAMES.BUY, NAMES.REQUEST_TRANSFER, NAMES. EARN],
  23: [NAMES.BUY, NAMES.REQUEST_TRANSFER, NAMES.EARN_NO_GROUPON],
  24: [NAMES.BUY, NAMES.REQUEST_TRANSFER, NAMES.EARN_NO_GROUPON],
    //24: [NAMES.BUY, NAMES.REQUEST_TRANSFER, NAMES.REINSTATE, NAMES.EARN_NO_GROUPON],
};

const getComponent = (type, persona, props) => NAMES_BY_PERSONA[persona].map(name => {
  let component = components[type][name];
  if (props){
    component = React.cloneElement(component, props);
  }
  return component;
});

export const getMainComponent = (persona, props) => getComponent(TYPES.MAIN, persona, props);

export const getNavComponent = persona => getComponent(TYPES.NAV, persona);

export const getScrollNavComponent = persona => getComponent(TYPES.SCROLL_NAV, persona);

export const getFixedNavComponent = persona => getComponent(TYPES.FIXED_NAV, persona);
