import React from 'react';
import Slider from 'react-slick';
import { Text } from './Text';
import { GrouponSlide } from './GrouponSlide';
import { MileagePlusSlide } from './MileagePlusSlide';
import { PointsHoundSlide } from './PointsHoundSlide';
import { EarnRedeemSlide } from './EarnRedeemSlide';
import { PremierAcceleratorSlide } from './PremierAcceleratorSlide';
import { AwardAcceleratorSlide } from './AwardAcceleratorSlide';
import { RewardPlusSlide } from './RewardPlusSlide';

export const Earn = ({languageCode, settings, url}) => (
    <section className="earn" id="earn">
        <h2><Text languageCode={languageCode} token="earn.header" /></h2>
        <Slider {...settings} className="earn-container">
            {/* <GrouponSlide languageCode={languageCode} url="groupon.link.url" slide_heading_text="groupon.slide.header" slide_text="groupon.slide.text" /> */}
            {/* <MileagePlusSlide languageCode={languageCode} url="mileageplus.link.url" slide_heading_text="mileageplus.slide.header" slide_text="mileageplus.slide.text" /> */}
            {/* <RewardPlusSlide languageCode={languageCode} url="rewardplus.link.url" slide_heading_text="rewardplus.slide.header" slide_text="rewardplus.slide.text" /> */}
            {/* <EarnRedeemSlide languageCode={languageCode} url="earnredeem.link.url" slide_heading_text="earnredeem.slide.header" slide_text="earnredeem.slide.text" /> */}
            {/* <PremierAcceleratorSlide languageCode={languageCode} url="premieraccelerator.link.url" slide_heading_text="premieraccelerator.slide.header" slide_text="premieraccelerator.slide.text" /> */}
            {/* <AwardAcceleratorSlide languageCode={languageCode} url="awardaccelerator.link.url" slide_heading_text="awardaccelerator.slide.header" slide_text="awardaccelerator.slide.text" /> */}
            {/* <PointsHoundSlide languageCode={languageCode} url="pointshound.link.url" slide_heading_text="pointshound.slide.header" slide_text="pointshound.slide.text" /> */}
        </Slider>
        <div className="no-slider earn-container">
             {/* <GrouponSlide languageCode={languageCode} url="groupon.link.url" slide_heading_text="groupon.slide.header" slide_text="groupon.slide.text" /> */}
             {/* <MileagePlusSlide languageCode={languageCode} url="mileageplus.link.url" slide_heading_text="mileageplus.slide.header" slide_text="mileageplus.slide.text" /> */}
            {/* <RewardPlusSlide languageCode={languageCode} url="rewardplus.link.url" slide_heading_text="rewardplus.slide.header" slide_text="rewardplus.slide.text" /> */}
            {/* <EarnRedeemSlide languageCode={languageCode} url="earnredeem.link.url" slide_heading_text="earnredeem.slide.header" slide_text="earnredeem.slide.text" /> */}
            {/* <PremierAcceleratorSlide languageCode={languageCode} url="premieraccelerator.link.url" slide_heading_text="premieraccelerator.slide.header" slide_text="premieraccelerator.slide.text" /> */}
            {/* <AwardAcceleratorSlide languageCode={languageCode} url="awardaccelerator.link.url" slide_heading_text="awardaccelerator.slide.header" slide_text="awardaccelerator.slide.text" /> */}
            {/* <PointsHoundSlide languageCode={languageCode} url="pointshound.link.url" slide_heading_text="pointshound.slide.header" slide_text="pointshound.slide.text" /> */}
        </div>
    </section>
);
