import React from 'react';
import { Text } from './Text';
import enUS from '../tokens/en-US.json';
import esES from '../tokens/es-ES.json';
import jpJP from '../tokens/jp-JP.json';
import ptBR from '../tokens/pt-BR.json';

export const translations = {
  'en-US': enUS,
  'es-ES': esES,
  'jp-JP': jpJP,
  'pt-BR': ptBR,
};

export const HomeDefault = ({ children, languageCode }) => (
<div className="home-content">
    <h1><Text languageCode={languageCode} token="homeDefault.header" /></h1>
    <p><Text languageCode={languageCode} token="homeDefault.subHeader" /></p>
    <div className="home-mobile-content_text">
        <p><Text languageCode={languageCode} token="homeDefault.text" /></p>
        <a href={translations[languageCode]["homeDefault.link.url"]} target="_blank" ><Text languageCode={languageCode} token="homeDefault.text.link" /></a>
    </div>
    {children}

</div>
);