import React from 'react';
import { Text } from './Text';
import { Button } from './Text';
import buyMilesIcon from '../assets/icons/buy_miles_teal.svg';
import buyMiles2BlueIcon from '../assets/icons/buy_miles_2_teal.svg';
import subscriptionIcon from '../assets/icons/new.png';
import subscriptionIconES from '../assets/icons/new_es.png';
import subscriptionIconPT from '../assets/icons/new_pt.png';
import subscriptionIconJA from '../assets/icons/new.png';

export const Buy = ({ languageCode, toggleHiddenBuy, toggleHiddenGift }) => {
    const icons = {
        "en-US":"New!",
        "es-ES":"¡Nuevo!",
        "pt-BR":"Novo!",
        "jp-JP":"New!"
    };
    
    return (
        <div className="main-item buy clearfix" id="buy">
            <div className="main-item_image">
                {/* <div className="main-item_quote" id="quote">
                    <p><Text languageCode={languageCode} token="buy.quote" /></p>
                    <p><Text languageCode={languageCode} token="buy.quote.name" /></p>
                </div> */}
                <div className="main-item_icon">
                    <img src={buyMilesIcon} alt=""/>
                </div>
            </div>
            <div className="main-item_content">
                <h3><Text languageCode={languageCode} token="buy.subheader" /></h3>
                <h2><Text languageCode={languageCode} token="buy.header" /></h2>
                <h4><Text languageCode={languageCode} token="buy.text" /></h4>
                <div className="main-item_buttons">
                <Button id="buy-cta-button" languageCode={languageCode} token="buy.button.yourself" icon={buyMilesIcon} url="buy.yourself.link.url" className="buy-link-pcta" tooltiptoken="buy.tooltip.yourself" promotoken="buy.onsale.token"/>
                <Button id="gift-cta-button" languageCode={languageCode} token="buy.button.another" icon={buyMiles2BlueIcon} url="buy.another.link.url" className="gift-link-pcta" tooltiptoken="buy.tooltip.another" promotoken="gift.onsale.token"/>
                <Button id="multiply-cta-button" languageCode={languageCode} token="buy.button.multiply" icon={icons[languageCode]} url="buy.multiply.link.url" className="gift-link-pcta" tooltiptoken="buy.tooltip.multiply" promotoken="multiply.onsale.token"/>
                <Button id="subscribe-cta-button" languageCode={languageCode} token="buy.button.new" icon={icons[languageCode]} url="buy.subscribe.link.url" className="gift-link-pcta" tooltiptoken="buy.tooltip.subscribe" promotoken="subscribe.onsale.token"/>
                </div>
                <p><Text languageCode={languageCode} token="buy.disclaimer1" /></p>
                <p><Text languageCode={languageCode} token="buy.disclaimer2" /></p>
                <p></p>
            </div>
        </div>
    );
}
