import React from 'react';
import Slider from 'react-slick';
import { Text } from './Text';
import { MileagePlusSlide } from './MileagePlusSlide';
import { GrouponSlide } from './GrouponSlide';
import { RewardPlusSlide } from './RewardPlusSlide';
import { AwardAcceleratorSlide } from './AwardAcceleratorSlide';
import { UnitedExplorerSlide } from './UnitedExplorerSlide';

export const EarnNoPH = ({languageCode, settings, url}) => (
    <section className="earn" id="earn">
        <h2><Text languageCode={languageCode} token="earn.header" /></h2>
        <Slider {...settings} className="earn-container">
            <GrouponSlide languageCode={languageCode} url="groupon.link.url" slide_heading_text="groupon.slide.header" slide_text="groupon.slide.text" />
            <MileagePlusSlide languageCode={languageCode} url="mileageplus.link.url" slide_heading_text="mileageplus.slide.header" slide_text="mileageplus.slide.text" />
            <RewardPlusSlide languageCode={languageCode} url="rewardplus.link.url" slide_heading_text="rewardplus.slide.header" slide_text="rewardplus.slide.text" />
            {/* <AwardAcceleratorSlide languageCode={languageCode} url="rewardplus.link.url" slide_heading_text="awardaccelerator.slide.header" slide_text="awardaccelerator.slide.text" /> */}
            {/* <UnitedExplorerSlide languageCode={languageCode} url="unitedcruises.link.url" slide_heading_text="unitedexplorer.slide.header" slide_text="unitedexplorer.slide.text" />   */}
        </Slider>
    </section>
);
