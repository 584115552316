
import React from 'react';
import { Text } from './Text';
import { Button } from './Text';
import enUS from '../tokens/en-US.json';
import esES from '../tokens/es-ES.json';
import jpJP from '../tokens/jp-JP.json';
import ptBR from '../tokens/pt-BR.json';

export const translations = {
  'en-US': enUS,
  'es-ES': esES,
  'jp-JP': jpJP,
  'pt-BR': ptBR,
};

export const RewardPlusSlide = ({ languageCode, url, slide_heading_text, slide_text }) => (
    <a href={translations[languageCode][url]} className="rewardplus earn-rewardplus-pcta" id="earn-rewardplus-pcta" target="_blank">
        <div className="earn-item_top earn-rewardplus-pcta"></div>
        <div className="earn-item_bottom earn-rewardplus-pcta">
            <h3 className="earn-rewardplus-pcta">{translations[languageCode][slide_heading_text]}</h3>
            <p className="earn-rewardplus-pcta">{translations[languageCode][slide_text]}</p>
        </div>
    </a>
);


