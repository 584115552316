import React from 'react';
import Slider from 'react-slick';
import { Text } from './Text';
import { MileagePlusSlide } from './MileagePlusSlide';
import { GiftcardsSlide } from './GiftcardsSlide';
import { RewardPlusSlide } from './RewardPlusSlide';
import { AwardAcceleratorSlide } from './AwardAcceleratorSlide';
import { MerchandiseAwardsSlide } from './MerchandiseAwardsSlide';
import { UnitedCruisesSlide } from './UnitedCruisesSlide';
import { UnitedExplorerSlide } from './UnitedExplorerSlide';
import { HotelAwardsSlide } from './HotelAwardsSlide';
import { CarSlide } from './CarSlide';
import { UnitedClubSlide } from './UnitedClubSlide';
import { ExclusivesSlide } from './ExclusivesSlide';
import { WifiSlide } from './WifiSlide';
import { PremierAcceleratorSlide } from './PremierAcceleratorSlide';

export const EarnNoGrouponNoPH = ({languageCode, settings, url}) => (
    <section className="earn" id="earn">
        <h2><Text languageCode={languageCode} token="earn.header" /></h2>
        <Slider {...settings} className="earn-container">
            {/* <HotelAwardsSlide languageCode={languageCode} url="hotelawards.link.url" slide_heading_text="hotelawards.slide.header" slide_text="hotelawards.slide.text" />    */}
            {/* <UnitedCruisesSlide languageCode={languageCode} url="unitedcruises.link.url" slide_heading_text="unitedcruises.slide.header" slide_text="unitedcruises.slide.text" /> */}
            {/* <CarSlide languageCode={languageCode} url="car.link.url" slide_heading_text="car.slide.header" slide_text="car.slide.text" /> */}
            {/* <UnitedClubSlide languageCode={languageCode} url="unitedclub.link.url" slide_heading_text="unitedclub.slide.header" slide_text="unitedclub.slide.text" /> */}
            {/* <ExclusivesSlide languageCode={languageCode} url="exclusives.link.url" slide_heading_text="exclusives.slide.header" slide_text="exclusives.slide.text" />  */}
            {/* <WifiSlide languageCode={languageCode} url="wifi.link.url" slide_heading_text="wifi.slide.header" slide_text="wifi.slide.text" /> */}
            {/* <MerchandiseAwardsSlide languageCode={languageCode} url="merchandiseawards.link.url" slide_heading_text="merchandiseawards.slide.header" slide_text="merchandiseawards.slide.text" /> */}
            {/* <GiftcardsSlide languageCode={languageCode} url="giftcards.link.url" slide_heading_text="giftcard.slide.header" slide_text="giftcard.slide.text" /> */}
            <MileagePlusSlide languageCode={languageCode} url="mileageplus.link.url" slide_heading_text="mileageplus.slide.header" slide_text="mileageplus.slide.text" />
            <RewardPlusSlide languageCode={languageCode} url="rewardplus.link.url" slide_heading_text="rewardplus.slide.header" slide_text="rewardplus.slide.text" />
            {/* <UnitedExplorerSlide languageCode={languageCode} url="unitedexplorer.link.url" slide_heading_text="unitedexplorer.slide.header" slide_text="unitedexplorer.slide.text" /> */}
            {/* <PremierAcceleratorSlide languageCode={languageCode} url="premieraccelerator.link.url" slide_heading_text="premieraccelerator.slide.header" slide_text="premieraccelerator.slide.text" /> */}
            {/* <AwardAcceleratorSlide languageCode={languageCode} url="awardaccelerator.link.url" slide_heading_text="awardaccelerator.slide.header" slide_text="awardaccelerator.slide.text" /> */}
        </Slider>
    </section>
);
