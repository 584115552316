import React from 'react';
import loadingGif from '../assets/loading-gif.gif';

export function IframeSignInGift(props) {
    const persona = props.persona;
    const toggleHiddenGift = props.toggleHiddenGift;
    if (persona != 0) {
        //Member already logged in
        return (
        <div className="iframe-con">
            <div className="iframeBG"></div>
            <div className="loading-gif">
                <img src={loadingGif} alt=""/>
            </div>
            {/* <iframe className="loginModal gift-modal hide" src="https://secure-stage.unitedmileageplus.com/CM_connect.jsp?CID=HlUJIwVXRrdQFIhiJkM402n9AP8IDPWs2JTb8F1ml35tDMksoyfkdMW913LqamApCvaNQ-rtCr4bznwUQG2Q.ndYz98xS32McavkULvmvsRmsbhebtF8GdW1.4.rzmTzqxgVX3aARlcw7Z4DbQCoYe5-9-kP9oh0zq1-GaM.NLL2mHaGkSav.3sJoUCQsSFMHWmY62M7zY6K8UquY1KHVuKU3I.RvnQpzzzptC8JXYIzubGf2QB6jO1ZlybibQsa4YTjsXF5d-6ycJE0ekcHyoGVQZ9kyAfH.HSPMiFbXzTPvaZ5-VWZqV77PypAKwGamy0.J7qzBbjGyW1Swq.7KnQ5N5CN9wyhPfoJtxjIzUKDQq..EpbGUJ9QgPxKjduEmZNRukdqnTOwrh3zZ9qjGkJI7GTblVm5kpCK.g2BXJrei0fdN91HUEFu3cbfHgDYQWFm8LkY0UpLbXuwEgVHQ2ksOvbZATm.iWk85hoXL2Prlvj6nA62CHjVXeV-NV.Aov6CgN6hXB9WacSNrZdgCWUHc9qYT-Yo.gaFHnT6hz07xlUEJIz5vkCBTItF9bU3&CKBR=1" frameBorder="0" ></iframe> */}
        </div>
        )
    }
    return (
        //Member not logged in
        <div className="iframe-con">
            <div className="iframeBG" onClick={toggleHiddenGift}></div>
            <div className="close-x" onClick={toggleHiddenGift}>x</div>
            <div className="loading-gif">
                <img src={loadingGif} alt=""/>
            </div>
            {/* <iframe className="loginModal gift-modal" src="https://secure-stage.unitedmileageplus.com/CM_connect.jsp?CID=HlUJIwVXRrdQFIhiJkM402n9AP8IDPWs2JTb8F1ml35tDMksoyfkdMW913LqamApCvaNQ-rtCr4bznwUQG2Q.ndYz98xS32McavkULvmvsRmsbhebtF8GdW1.4.rzmTzqxgVX3aARlcw7Z4DbQCoYe5-9-kP9oh0zq1-GaM.NLL2mHaGkSav.3sJoUCQsSFMHWmY62M7zY6K8UquY1KHVuKU3I.RvnQpzzzptC8JXYIzubGf2QB6jO1ZlybibQsa4YTjsXF5d-6ycJE0ekcHyoGVQZ9kyAfH.HSPMiFbXzTPvaZ5-VWZqV77PypAKwGamy0.J7qzBbjGyW1Swq.7KnQ5N5CN9wyhPfoJtxjIzUKDQq..EpbGUJ9QgPxKjduEmZNRukdqnTOwrh3zZ9qjGkJI7GTblVm5kpCK.g2BXJrei0fdN91HUEFu3cbfHgDYQWFm8LkY0UpLbXuwEgVHQ2ksOvbZATm.iWk85hoXL2Prlvj6nA62CHjVXeV-NV.Aov6CgN6hXB9WacSNrZdgCWUHc9qYT-Yo.gaFHnT6hz07xlUEJIz5vkCBTItF9bU3&CKBR=1" frameBorder="0" ></iframe> */}
        </div>
    )
  }
