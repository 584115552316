import React from 'react';
import { Text } from './Text';
import { Button } from './Text';
import enUS from '../tokens/en-US.json';
import esES from '../tokens/es-ES.json';
import jpJP from '../tokens/jp-JP.json';
import ptBR from '../tokens/pt-BR.json';
import { TransferMarriott } from './TransferMarriott';
import transferMilesIcon from '../assets/icons/transfer_miles_teal.svg';
import requestMilesBlueIcon from '../assets/icons/request_miles_teal.svg';

export const translations = {
    'en-US': enUS,
    'es-ES': esES,
    'jp-JP': jpJP,
    'pt-BR': ptBR,
  };

export const Transfer = ({languageCode, toggleHiddenRequestTransfer}) => (
    <div className="main-item transfer clearfix" id="transfer">
        <div className="main-item_image">
            {/* <div className="main-item_quote">
                <p><Text languageCode={languageCode} token="transfer.quote" /></p>
                <p><Text languageCode={languageCode} token="transfer.quote.name" /></p>
            </div> */}
            <div className="main-item_icon">
                <img src={transferMilesIcon} alt=""/>
            </div>
        </div>
        <div className="main-item_content">
            <h3><Text languageCode={languageCode} token="transfer.subheader" /></h3>
            <h2><Text languageCode={languageCode} token="transfer.header" /></h2>
            <h4><Text languageCode={languageCode} token="transfer.text" /></h4>
            <div className="main-item_buttons">
                <Button id="transfer-cta-button" languageCode={languageCode} token="transfer.button.yourself" icon={transferMilesIcon} url="transfer.link.url" className="transfer-link-pcta" tooltiptoken="transfer.tooltip.yourself" promotoken="transfer.onsale.token"/>
                <Button id="request-transfer-cta-button" languageCode={languageCode} token="transfer.button.another" icon={requestMilesBlueIcon} url="transfer.another.link.url" className="request-transfer-link-pcta" tooltiptoken="trasnfer.tooltip.another" promotoken="reinstate.onsale.token" />
            </div>
            <p><Text languageCode={languageCode} token="transfer.disclaimer1" /></p>
            <p><Text languageCode={languageCode} token="transfer.disclaimer2" /></p>
            {/* <TransferMarriott languageCode={languageCode} /> */}
        </div>
    </div>
);
