import React from 'react';
import { displayLanguage } from '../App';
import { toggleLanSelect } from '../App';

// const createOnInput = callback => (event) => {
//   const { id } = event.target;
//   callback(id);
//   console.log("fired");
// };


export const LanguageSelector = ({ callback, languageCode, displayLanguage, toggleLanSelect, createOnInput, setTitle }) => (
 
    <ul aria-activedescendant="languages" aria-controls="ESC key to close menu and ENTER key to select language" className={displayLanguage ? 'lanSelect lanSelect-show' : 'lanSelect'} >
        <button className={(languageCode == 'en-US') ? 'lang-button-active' : 'lang-button'} role="language option" tabIndex="0" onClick={() => {callback("en-US"); toggleLanSelect();}}>English</button>
        <button className={(languageCode == 'es-ES') ? 'lang-button-active' : 'lang-button'} role="language option" tabIndex="0" onClick={() => {callback('es-ES'); toggleLanSelect();}}>Español</button>
        <button className={(languageCode == 'pt-BR') ? 'lang-button-active' : 'lang-button'} role="language option" tabIndex="0" onClick={() => {callback('pt-BR'); toggleLanSelect();}}>Português</button>
        <button className={(languageCode == 'jp-JP') ? 'lang-button-active' : 'lang-button'} role="language option" tabIndex="0" onClick={() => {callback('jp-JP'); toggleLanSelect();}}>日本語</button>
    </ul>
);

// ES6 Methods Do Not Work for IE : (

// const LANGS = {
//     "en-US": "English",
//     "es-ES": "Español",
//     "pt-BR": "Português",
//     "jp-JP": "日本語",
// }

// export const LanguageSelector = ({ callback, languageCode, displayLanguage, toggleLanSelect, createOnInput }) => (
//     <ul className={displayLanguage ? 'lanSelect lanSelect-show' : 'lanSelect'} >
//     {Object.entries(LANGS).map(([code, label]) => {
//         return <li key={code} tabIndex="0" onClick={() => {
//             callback(code);
//             toggleLanSelect();
//         }}>{label}</li>
//     })}
//     </ul>
// );

