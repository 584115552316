import React from 'react';
import { Text } from './Text';
import { Button } from './Text';
import pointsHoundMilesIcon from '../assets/icons/pointshound_miles_teal.svg';
import pointsHoundMilesBlueIcon from '../assets/icons/pointshound_miles_2_teal.svg';

export const PointsHound = ({ languageCode, toggleHiddenBuy, toggleHiddenGift }) => (
    <div className="main-item pointshound clearfix" id="pointshound">
        <div className="main-item_image">
            {/* <div className="main-item_quote" id="quote">
                <p><Text languageCode={languageCode} token="pointshound.quote" /></p>
                <p><Text languageCode={languageCode} token="pointshound.quote.name" /></p>
            </div> */}
            <div className="main-item_icon">
                <img src={pointsHoundMilesIcon} alt=""/>
            </div>
        </div>
        <div className="main-item_content">
            <h3><Text languageCode={languageCode} token="pointshound.subheader" /></h3>
            <h2><Text languageCode={languageCode} token="pointshound.header" /></h2>
            <h4><Text languageCode={languageCode} token="pointshound.text" /></h4>
            <div className="main-item_buttons">
                <Button languageCode={languageCode} token="pointshound.button.yourself" icon={pointsHoundMilesIcon} className="pointshound-link-pcta" url="pointshound.link.url" />
                <Button languageCode={languageCode} token="pointshound.button.another" icon={pointsHoundMilesBlueIcon} className="gift-link-pcta" url="earnredeem.link.url" />
            </div>
            <p><Text languageCode={languageCode} token="pointshound.disclaimer1" /></p>
            <p><Text languageCode={languageCode} token="pointshound.disclaimer2" /></p>
            <p></p>
        </div>
    </div>
);
